// Bootstrap Framework
import './scss/index.scss';
import 'popper.js';
import 'bootstrap';
import jquery from 'jquery';
window.jQuery = window.$ = jquery;
import "slick-carousel";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Sticky from "sticky-js";
import 'jquery-match-height';
import '@fancyapps/fancybox';

$(function () {

    //#region bootstrap navbar + hamburger + body scroll lock
    $('#navbarMain').on('show.bs.collapse', function (e) {
        $('.navbar-toggler .hamburger').addClass('is-active');
        setTimeout(() => {
            $('.navbar').addClass('is-open');
        }, 300)
        let scrollableNav = document.querySelector('#navbarMain');
        disableBodyScroll(scrollableNav);
    });
    $('#navbarMain').on('hidden.bs.collapse', function (e) {
        $('.navbar-toggler .hamburger').removeClass('is-active');
        $('.navbar').removeClass('is-open');
        let scrollableNav = document.querySelector('#navbarMain');
        enableBodyScroll(scrollableNav);
    });
    //#endregion

    //#region backtotop
    $('.backtotop').on('click', function (e) {
        e.preventDefault();
        window.scrollTo(0, 0);
    });
    //#endregion

    //#region slider
    $('.slider').slick({
        fade: true,
        prevArrow: '.slider__arrow--prev',
        nextArrow: '.slider__arrow--next',
        dots: true,
        appendDots: '.slider__dots',
        autoplay: true,
        pauseOnDotsHover: false,
        pauseOnHover: false,
        pauseOnFocus: false
    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.slider__title').removeClass('slider__title--active');
    }).on('afterChange', function (event, slick, currentSlide) {
        $(`.slider__title[data-slide="${currentSlide}"]`).addClass('slider__title--active');
    });
    //#endregion

    //#region filters
    $('.filters__toggle').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('filters__toggle--open');
        $('.filters__wrapper').toggleClass('filters__wrapper--open');
    });
    //#endregion

    //#region carousel
    $('.carousel').slick({
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    //#endregion


});